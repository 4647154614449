<template>
	<div class="section-4">
		<div class="container-section">
			<div class="org-section">
				<div class="full-org-image">
					<svg-section-3-org />

					<div class="btn-view-video">
						<button @click="openPricing">
							Planifică vizual cu TBF Digital
							<icon-arrow class="icon-arrow" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import SvgSection3Org from '../../assets/landing/SvgVideoOrg'
	import IconArrow from '../../assets/landing/Arrow'

	export default {
		data() {
			return {

			}
		},
		components: {
			IconArrow,
			SvgSection3Org
		},
		mounted(){

		},
		methods: {
			openPricing(){
				// window.open(this.$router.resolve({name: 'pricing'}).href, '_blank')
				this.$router.push({name: 'pricing'})
			}
		}
	}
</script>